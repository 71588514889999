import React from "react";
import { Box, Flex } from "reflexbox";

export function H2(props) {
  const { title, fontSize, textAlign, ...rest } = props;
  const color = rest.color || "black";
  const rightSide = rest.rightSide || false;
  const actualFontSize = fontSize || ["32pt", "48pt"];

  return (
    <Flex flexDirection={["column", "row"]} alignItems="center" overflowX="hidden" {...rest}>
      <Box
        sx={{
          letterSpacing: ["", "12px"],
          fontSize: actualFontSize,
          color: "transparent",
          fontWeight: "bold",
          WebkitTextStroke: [`2px ${color}`, `3px ${color}`],
          textAlign: textAlign || "center"
        }}
        order={rightSide ? [0, 2] : 0}
      >
        {title}
      </Box>
      <Box
        ml={rightSide ? [] : ["", "32px"]}
        mr={rightSide ? ["", "32px"] : []}
        flex={["", "1"]}
        sx={{ borderTop: "solid 1px black", height: "1px", width: ["125px", ""] }}
      />
    </Flex>
  );
}
