import React, { useEffect, useState } from "react";
import { Modal } from "@material-ui/core";
import { Box, Flex } from "reflexbox";
import { NewsletterForm } from "../newsletter";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { api } from "../../api";
import { useLanguage } from "../../hooks/useLanguage";

const useStyles = makeStyles({
  button: {
    cursor: "pointer",
    border: "1px solid #707070",
    padding: "8px 12px",
    color: "black",
    fontWeight: "bold",
    fontSize: "13pt",
    backgroundColor: "transparent",
    display: "flex",
    width: "100%",
    alignItems: "center"
  }
});

export function EmailPopup(props) {
  const classes = useStyles();

  const language = useLanguage();

  const { t } = useTranslation("common");

  const [open, setOpen] = useState(false);
  const [image, setImage] = useState();
  const handleClose = () => setOpen(false);

  useEffect(() => {
    api.get(`${language}/textpages/1280160`).then((res) => {
      const textpage = res.data.textpage;
      const re = new RegExp(`img src=\\"(http.*?)\\"`, "g");
      if (re) {
        const img = re.exec(textpage.content)[1];
        if (img) {
          setImage(img);
        }
      }
    });
  }, [language]);

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 5000);
  }, []);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: ["80%", "60%"],
          backgroundColor: "white",
          borderRadius: "5px",
          boxShadow: 24
        }}
      >
        <Flex flexDirection={["column", "row"]}>
          <Box flex="1" display={["none", "block"]}>
            <img
              src={image}
              style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "5px 0 0 5px" }}
            />
          </Box>
          <Box flex="1">
            <Box sx={{ p: 4 }}>
              <Flex justifyContent="flex-end" display="flex">
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{ color: "black", fontSize: "20pt", cursor: "pointer" }}
                  onClick={handleClose}
                />
              </Flex>
              <Flex flexDirection="column" alignItems="center" pb="16px">
                <Flex sx={{ fontSize: ["24pt", "28pt"] }}>
                  <Box justifySelf="center">{t("emailheader")}</Box>
                </Flex>
                <p>{t("emailtext")}</p>
                <NewsletterForm width="auto" stayOpen={true} buttonStyle={classes.button} onSuccess={handleClose} />
              </Flex>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Modal>
  );
}
