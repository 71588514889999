import React, { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useTheme } from "emotion-theming";
import { Box, Flex } from "reflexbox";
import { Navbar } from "../../components/navbar";
import { H2 } from "../../components/h2";
import { HR } from "../../components/hr";
import { Container } from "../../components/container";
import { api } from "../../api";
import { useLanguage } from "../../hooks/useLanguage";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  p1: {
    color: ({ theme }) => theme.colors.primary,
    fontWeight: "bold"
  }
});

function LeftRightText(props) {
  const theme = useTheme();

  const classes = useStyles({ theme });

  const data = props.data;

  return (
    <>
      <Box display={["none", "block"]}>
        {data.map((pair, index) => (
          <Flex key={index} my="16px">
            <Box flex="1" className={classes.p1}>
              <div className={classes.p1} dangerouslySetInnerHTML={{ __html: pair.left }} />
            </Box>
            <Box flex="1" textAlign="right">
              <div dangerouslySetInnerHTML={{ __html: pair.right }} />
            </Box>
          </Flex>
        ))}
      </Box>
      <Box display={["block", "none"]}>
        <Flex flexDirection={"column"}>
          {data.map((text, index) => (
            <Box key={index} style={{ textAlign: "center" }} mt={index === 0 ? "48px" : "24px"}>
              <div className={classes.p1} dangerouslySetInnerHTML={{ __html: text.left }} />
              <div dangerouslySetInnerHTML={{ __html: text.right }} />
            </Box>
          ))}
        </Flex>
      </Box>
    </>
  );
}

function Leader(props) {
  const { name, title, image, greyImage } = props;

  return (
    <>
      <Flex flexDirection="column">
        <Box
          sx={{
            border: "2px solid transparent",
            ":hover": {
              transform: "scale(1.05)"
            }
          }}
        >
          <img
            src={greyImage}
            style={{ height: "100%", width: "100%" }}
            onMouseEnter={(e) => {
              e.currentTarget.src = image;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.src = greyImage;
            }}
          />
        </Box>
        <Box mt="16px">
          <b>{name}</b>
        </Box>
        <Box mt="8px">{title}</Box>
      </Flex>
    </>
  );
}

function extractLeftRightFromTextpage(textpage) {
  if (!textpage?.content) {
    return [];
  }

  const splitRegex = new RegExp("<p>&lt;Split&gt;<\\/p>");
  const [leftContent, rightContent] = textpage.content.split(splitRegex);
  const textRegex = new RegExp("<p>(.*?)<\\/p>", "gm");
  const left = [...leftContent.matchAll(textRegex)].map((match) => match[1]).filter((x) => x.trim().length !== 0);
  const right = [...rightContent.matchAll(textRegex)].map((match) => match[1]).filter((x) => x.trim().length !== 0);
  const count = Math.max(left.length, right.length);
  const leftRight = [];
  for (let index = 0; index < count; index++) {
    leftRight.push({ left: left[index], right: right[index] });
  }
  return leftRight;
}

function extractLeadersFromTextpage(textpage) {
  if (!textpage?.content) {
    return [];
  }

  const parser = new DOMParser();
  const table = parser.parseFromString(textpage.content, "text/html");
  const tableRows = table.getElementsByTagName("tr");

  const data = [];
  for (let row of tableRows) {
    const rowData = row.getElementsByTagName("td");
    const greyImage = rowData[0]?.getElementsByTagName("img")[0]?.getAttribute("src");
    const image = rowData[1]?.getElementsByTagName("img")[0]?.getAttribute("src");
    const name = rowData[2]?.innerText;
    const title = rowData[3]?.innerText;

    if (greyImage && image && name && title) {
      data.push({ greyImage, image, name, title });
    }
  }

  return data;
}

export default () => {
  const theme = useTheme();

  const language = useLanguage();

  const { t } = useTranslation("about");

  const [leaders, setLeaders] = useState([]);
  const [description, setDescription] = useState();
  const [missions, setMissions] = useState([]);
  const [values, setValues] = useState([]);

  useEffect(() => {
    api.get(`${language}/textpages/966691`).then((res) => {
      const textpage = res.data.textpage;
      setDescription(textpage.content);
    });
    api.get(`${language}/textpages/966707`).then((res) => {
      const textpage = res.data.textpage;
      setMissions(extractLeftRightFromTextpage(textpage));
    });
    api.get(`${language}/textpages/966708`).then((res) => {
      const textpage = res.data.textpage;
      setValues(extractLeftRightFromTextpage(textpage));
    });
    api.get(`${language}/textpages/1259224`).then((res) => {
      const textpage = res.data.textpage;
      setLeaders(extractLeadersFromTextpage(textpage));
    });
  }, [language]);

  return (
    <>
      <Navbar />

      <H2 title={t("Leadership")} />
      <Container>
        <Flex my="32px" justifyContent="center">
          <Box>
            <Grid container spacing={2}>
              {leaders.map((leader, index) => (
                <Grid item key={index} xs={4} md={2}>
                  <Leader name={leader.name} title={leader.title} image={leader.image} greyImage={leader.greyImage} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Flex>
      </Container>

      <H2 title={t("Our Team")} />
      <Container>
        <div dangerouslySetInnerHTML={{ __html: description }} />
        {/* <Flex justifyContent="center" mt="96px">
          <Box
            sx={{
              width: ["95vw", "73vw"],
              height: ["calc(95vw/1.77)", "calc(73vw/1.77)"],
              maxWidth: "1391px",
              maxHeight: "792px"
            }}
          >
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/qdjOVj69ZoM"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="https://www.youtube.com/embed/qdjOVj69ZoM"
            ></iframe>
          </Box>
        </Flex> */}
      </Container>

      <Box mt="128px">
        <H2 title={t("Mission")} />
      </Box>

      <Container mt={["16px", "32px"]}>
        <LeftRightText data={missions} />
      </Container>

      <Box mt="128px">
        <H2 title={t("Values")} />
      </Box>

      <Container mt={["16px", "32px"]}>
        <LeftRightText data={values} />
      </Container>

      <HR color={theme.colors.secondary} mt="96px" mb="128px" />
    </>
  );
};
