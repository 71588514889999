import React, { useContext, useState, useEffect } from "react";
import { Box } from "reflexbox";
import { TextField } from "@material-ui/core";
import { CatalogContext } from "../../contexts/catalog-context";
import Autocomplete from "@material-ui/lab/Autocomplete";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListSubheader from "@material-ui/core/ListSubheader";
import { VariableSizeList } from "react-window";
import { useTheme as useMaterialTheme, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { slideInDown, slideOutUp } from "react-animations";
import { useSortedBrands } from "../../hooks/useSortedBrands";

const MenuStyles = makeStyles({
  "@keyframes slideInDownAnimation": slideInDown,
  "@keyframes slideOutUpAnimation": slideOutUp,
  openMenu: {
    animation: "$slideInDownAnimation 0.1s linear"
  },
  closeMenu: {
    animation: "$slideOutUpAnimation 0.1s linear"
  }
});

const useStyles = makeStyles({
  listbox: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0
    }
  }
});

export function Search({ onChangeCallback }) {
  const classes = useStyles();

  const { catalog } = useContext(CatalogContext);
  const brands = useSortedBrands();

  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);

  useEffect(() => {
    const list = [];

    for (let i = 0; i < catalog.length; i++) {
      const element = catalog[i];
      if (4327915 in element.categories) {
        list.push({
          image: element.image.src,
          url: `/products/${element.id}`,
          title: element.title
        });
      }
    }

    for (let i = 0; i < brands.length; i++) {
      const element = brands[i];
      list.push({
        image: element.image.src,
        url: `/manufacturers/${element.id}`,
        title: element.title
      });
    }

    list.sort((a, b) => a.title.localeCompare(b.title, "en", { sensitivity: "base" }));

    console.log("list", list);
    console.log("brands", brands);
    setAutoCompleteOptions(list);
  }, [brands, catalog]);

  const [value, setValue] = useState(null);

  const history = useHistory();

  return (
    <>
      <Box p={["0 32px 32px", "0"]}>
        <Autocomplete
          value={value}
          onChange={(event, newValue) => {
            if (typeof newValue === "string") {
              const index = autoCompleteOptions.findIndex((x) => x.title.toLowerCase() === newValue.toLowerCase());
              if (index !== -1) {
                history.push(autoCompleteOptions[index].url);
                if (typeof onChangeCallback === "function") {
                  onChangeCallback();
                }
              }
            } else {
              history.push(newValue.url);
              if (typeof onChangeCallback === "function") {
                onChangeCallback();
              }
            }
          }}
          freeSolo
          options={autoCompleteOptions}
          // groupBy={(option) => option.brand.title}
          // renderGroup={renderGroup}
          classes={classes}
          disableListWrap
          ListboxComponent={ListboxComponent}
          getOptionLabel={(option) => option?.title ?? ""}
          renderInput={(params) => <TextField {...params} placeholder="Search..." variant="outlined" />}
          renderOption={(option) => (
            <>
              <img src={option.image} width={["32px", "64px"]} height={["32px", "64px"]} />
              <Box ml="8px">{option.title}</Box>
            </>
          )}
        />
      </Box>
    </>
  );
}

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING
    }
  });
}

const renderGroup = (params) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children
];

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function (props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useMaterialTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 72 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});
