import React, { useState, useEffect } from "react";
import { api } from "../api";

export const ProjectContext = React.createContext();

export const ProjectContextProvider = (props) => {
  const [context, setContext] = useState({
    projects: [],
    loading: true
  });

  useEffect(() => {
    // TODO: if there are ever more than 250 blogs, we would have to retrieve all of them then filter
    api.get(`/en/blogs/articles?blog_id=8891&limit=250`).then((res) => {
      const obj = [];
      const results = res.data.blogArticles.filter((x) => x.blog.resource.id === 8891).filter((x) => x.isPublished);
      for (let i = 0; i < results.length; i++) {
        for (let j = i + 1; j < results.length; j++) {
          if (results[i].summary === results[j].summary && results[i].summary.length !== 0) {
            obj.push({
              id: results[i].id,
              [results[i].language.locale]: results[i],
              [results[j].language.locale]: results[j],
              mainLanguage: results[i].language.locale
            });
            obj.push({
              id: results[j].id,
              [results[i].language.locale]: results[i],
              [results[j].language.locale]: results[j],
              mainLanguage: results[j].language.locale
            });
          }
        }
      }
      setContext({ projects: obj, loading: false });
    });
  }, []);

  return <ProjectContext.Provider value={context}>{props.children}</ProjectContext.Provider>;
};
