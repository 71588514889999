import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Flex } from "reflexbox";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { useTheme } from "emotion-theming";
import { slideInDown, slideOutUp } from "react-animations";

const MenuItemStyles = makeStyles({
  lineStyle: {
    borderStyle: "solid",
    borderTopWidth: "2px",
    borderBottomWidth: "0",
    borderLeftWidth: "0",
    borderRightWidth: "0",
    borderColor: ({ theme }) => theme.colors.primary,
    width: "100%"
  },

  link: {
    textDecoration: "none",
    color: "black",
    cursor: "pointer",
    letterSpacing: "2px",
    "&:hover": {
      color: ({ theme }) => theme.colors.primary
    }
  },

  "@keyframes widthAnimationOpen": {
    from: {
      width: "0%"
    },
    to: {
      width: "100%"
    }
  },
  "@keyframes widthAnimationClose": {
    from: {
      width: "100%"
    },
    to: {
      width: "0%"
    }
  },
  openStyle: {
    animation: "$widthAnimationOpen 0.1s linear"
  },
  closedStyle: {
    animation: "$widthAnimationClose 0.1s linear"
  },
  "@keyframes slideInDownAnimation": slideInDown,
  "@keyframes slideOutUpAnimation": slideOutUp,
  openChildren: {
    animation: "$slideInDownAnimation 0.1s linear"
  },
  closeChildren: {
    animation: "$slideOutUpAnimation 0.1s linear"
  }
});

export function MenuItem({ page, onClickCallback }) {
  const theme = useTheme();

  const classes = MenuItemStyles({ theme });

  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    if (open) {
      setShow(true);
    }
  }, [open]);

  const lineMarkup = (isRight) =>
    show && (
      <Flex
        alignItems="center"
        width={["80px", "200px"]}
        mx={["24px", "48px"]}
        justifyContent={isRight ? "flex-start" : "flex-end"}
      >
        <div
          className={clsx(classes.lineStyle, { [classes.openStyle]: open, [classes.closedStyle]: !open })}
          onAnimationEnd={() => {
            if (!open) {
              setShow(false);
            }
          }}
        />
      </Flex>
    );

  let linkMarkup;

  if (page.children && page.children.length !== 0) {
    linkMarkup = (
      <Flex flexDirection="column" alignItems="center">
        <Flex style={{ zIndex: 10000 }}>
          {lineMarkup(false)}
          <Box
            onClick={toggleOpen}
            className={classes.link}
            style={{
              fontWeight: "bold"
            }}
          >
            {page.title}
          </Box>
          {lineMarkup(true)}
        </Flex>
        {show &&
          page.children.map((child, index) => (
            <Box
              mt="8px"
              key={index}
              className={clsx({ [classes.openChildren]: open, [classes.closeChildren]: !open })}
            >
              <Link to={`${page.link}${child.link}`} className={classes.link} onClick={onClickCallback}>
                {child.title}
              </Link>
            </Box>
          ))}
      </Flex>
    );
  } else {
    linkMarkup = (
      <Flex justifyContent="center">
        <Link
          to={page.link}
          style={{
            fontWeight: "bold"
          }}
          className={classes.link}
          onClick={onClickCallback}
        >
          {page.title}
        </Link>
      </Flex>
    );
  }

  return <Box sx={{ fontSize: ["18pt", "24pt"], paddingBottom: "24px" }}>{linkMarkup}</Box>;
}
