import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Flex } from "reflexbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Menu } from "./menu";
import { useLanguage } from "../../hooks/useLanguage";
import { Search } from "./search";
import SearchIcon from "@material-ui/icons/Search";
import ScrollAnimation from "react-animate-on-scroll";

const useStyles = makeStyles({
  navbar: {
    zIndex: 100,
    position: "relative",
    transition: "height 0.5s linear"
  },
  opaque: {
    backgroundColor: "rgba(255, 255, 255, 1)"
  },
  menuOpened: {
    position: "fixed",
    left: 0,
    top: 0,
    right: 0,
    zIndex: 100
  }
});

export function Navbar(props) {
  const classes = useStyles();
  const color = props.color || "black";

  const language = useLanguage();
  const { i18n } = useTranslation();

  const [menuOpened, setMenuOpened] = useState(false);
  const [opaque, setOpaque] = useState(menuOpened);
  const [searchOpen, setSearchOpen] = useState(false);

  useEffect(() => {
    if (menuOpened) {
      setOpaque(true);
    }
  }, [menuOpened]);

  return (
    <>
      {menuOpened && <Box sx={{ height: ["112px", "172px"] }} />}
      <Flex
        flexDirection="column"
        className={clsx(classes.navbar, { [classes.menuOpened]: menuOpened, [classes.opaque]: opaque })}
      >
        <Flex pt="16px" px={["8px", "32px"]} pb="16px" justifyContent="space-between">
          <Box>
            <Link to="/">
              <Box height={["64px", "160px"]}>
                <img src={`/assets/logo-en.png`} height="100%" alt="LG Acoustic" title="LG Acoustic" />
              </Box>
            </Link>
          </Box>
          <Flex>
            {searchOpen && (
              <Box width="600px" m="0 16px" display={["none", "block"]}>
                <ScrollAnimation animateIn="fadeIn" duration={0.3} offset={0}>
                  <Search
                    onChangeCallback={() => {
                      setSearchOpen(false);
                    }}
                  />
                </ScrollAnimation>
              </Box>
            )}
            <Box m="16px">
              <SearchIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSearchOpen(!searchOpen);
                }}
              />
            </Box>
            <Box m="16px" style={{ color: menuOpened ? "black" : color, textDecoration: "underline" }}>
              {language &&
                (language === "en" ? (
                  <Box
                    onClick={() => {
                      i18n.changeLanguage("fr");
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    FR
                  </Box>
                ) : (
                  <Box
                    onClick={() => {
                      i18n.changeLanguage("en");
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    EN
                  </Box>
                ))}
            </Box>
            <Box m="16px">
              <FontAwesomeIcon
                icon={menuOpened ? faTimes : faBars}
                style={{ color: menuOpened ? "black" : color, fontSize: "20pt", cursor: "pointer" }}
                onClick={() => setMenuOpened(!menuOpened)}
              />
            </Box>
          </Flex>
        </Flex>
        {searchOpen && (
          <Box display={["block", "none"]}>
            <ScrollAnimation animateIn="slideInDown" duration={0.3}>
              <Search
                onChangeCallback={() => {
                  setSearchOpen(false);
                }}
              />
            </ScrollAnimation>
          </Box>
        )}
      </Flex>
      <Menu
        open={menuOpened}
        onAnimationEndCallback={() => {
          setOpaque(false);
        }}
        onClickCallback={() => {
          setMenuOpened(false);
        }}
      />
    </>
  );
}
