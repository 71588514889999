import React, { useEffect, useState } from "react";
import { Flex } from "reflexbox";
import Pagination from "../../components/pagination";
import { FormControl, InputLabel, MenuItem, Select, Chip, makeStyles } from "@material-ui/core";
import { Box } from "reflexbox";
import { Product } from "../../components/product";
import { useTranslation } from "react-i18next";
import { Call, Cancel } from "@material-ui/icons";
import { api } from "../../api";
import { useLanguage } from "../../hooks/useLanguage";

const useStyles = makeStyles({
  chip: {
    color: "white",
    borderRadius: "4px",
    marginTop: "16px"
  }
});

function Filter({ categories, subCategories, onChangeCallback, defaultSubCategory }) {
  const classes = useStyles();

  const { t } = useTranslation("common");

  const [category, setCategory] = useState(defaultSubCategory);
  const [subCategory, setSubCategory] = useState();

  useEffect(() => {
    if (typeof onChangeCallback === "function") {
      onChangeCallback(category, subCategory);
    }
  }, [onChangeCallback, category, subCategory]);

  useEffect(() => {
    if (subCategories.length === 0) {
      setSubCategory("");
    }
  }, [subCategories]);

  return (
    <>
      {category && (
        <Chip
          className={classes.chip}
          label={`${t("Category")}: ${category.title}`}
          color="primary"
          clickable
          onDelete={() => {
            setCategory("");
          }}
          deleteIcon={<Cancel style={{ color: "white" }} />}
        />
      )}
      {!category && (
        <FormControl style={{ width: "100px" }}>
          <InputLabel>{t("Category")}</InputLabel>
          <Select
            value={category}
            onChange={(event) => {
              setCategory(event.target.value);
            }}
          >
            {categories.map((category, index) => (
              <MenuItem key={index} value={category}>
                {category.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {subCategory && (
        <Chip
          className={classes.chip}
          label={`${t("Sub Category")}: ${subCategory.title}`}
          color="primary"
          clickable
          onDelete={() => {
            setSubCategory("");
          }}
          deleteIcon={<Cancel style={{ color: "white" }} />}
        />
      )}
      {!subCategory && subCategories?.length > 0 && (
        <FormControl style={{ width: "150px" }}>
          <InputLabel>{t("Sub Category")}</InputLabel>
          <Select
            value={subCategory}
            onChange={(event) => {
              setSubCategory(event.target.value);
            }}
          >
            {subCategories.map((subCategory, index) => (
              <MenuItem key={index} value={subCategory}>
                {subCategory.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
}

export function ProductsGrid(props) {
  const { products, subCategories: categories, defaultSubCategory } = props;

  const { t } = useTranslation(["common", "product-grid"]);
  const language = useLanguage();

  const [showProducts, setShowProducts] = useState([...products]);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [totalPages, setTotalPages] = useState(Math.ceil(products.length / itemsPerPage));
  const [sliceStart, setSliceStart] = useState(0);
  const [sorting, setSorting] = useState("");
  const [subCategories, setSubCategories] = useState([]);

  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");

  useEffect(() => {
    setTotalPages(Math.ceil(showProducts.length / itemsPerPage));
  }, [itemsPerPage, showProducts]);

  useEffect(() => {
    let newProducts = [...products];

    if (category) {
      if (subCategory) {
        newProducts = newProducts.filter((x) => subCategory.id in x.categories);
      } else {
        newProducts = newProducts.filter((x) => category.id in x.categories);
      }
    }

    if (sorting === "name-asc") {
      newProducts.sort((a, b) => a.title.localeCompare(b.title));
    } else if (sorting === "name-desc") {
      newProducts.sort((a, b) => b.title.localeCompare(a.title));
    } else if (sorting === "date-asc") {
      newProducts.sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime());
    } else if (sorting === "date-desc") {
      newProducts.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
    }

    setShowProducts(newProducts);
  }, [category, subCategory, sorting, products]);

  useEffect(() => {
    if (category?.id) {
      api.get(`/${language}/categories`, { params: { parent: category.id } }).then((res) => {
        setSubCategories(res.data);
      });
    } else {
      setSubCategories([]);
    }
  }, [category]);

  return (
    <Flex flexDirection="column">
      <Flex alignItems={["center", "flex-start"]} flexDirection="column">
        <Flex flexWrap="wrap" style={{ gridGap: "8px 32px" }}>
          <Box>
            <FormControl style={{ width: "120px" }}>
              <InputLabel>{t("product-grid:Show")}</InputLabel>
              <Select
                value={itemsPerPage}
                onChange={(event) => {
                  setItemsPerPage(event.target.value);
                }}
              >
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={24}>24</MenuItem>
                <MenuItem value={48}>48</MenuItem>
                <MenuItem value={96}>96</MenuItem>
                <MenuItem value={144}>144</MenuItem>
                <MenuItem value={250}>250</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box>
            <FormControl style={{ width: "200px" }}>
              <InputLabel>{t("product-grid:Sort by")}</InputLabel>
              <Select
                value={sorting}
                onChange={(event) => {
                  setSorting(event.target.value);
                }}
              >
                <MenuItem value="name-asc">{t("product-grid:Name")} : A-Z</MenuItem>
                <MenuItem value="name-desc">{t("product-grid:Name")} : Z-A</MenuItem>
                <MenuItem value="date-asc">
                  {t("product-grid:Date")} : {t("product-grid:Newest first")}
                </MenuItem>
                <MenuItem value="date-desc">
                  {t("product-grid:Date")} : {t("product-grid:Oldest first")}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          {categories && (
            <Filter
              categories={categories}
              subCategories={subCategories}
              onChangeCallback={(category, subCategory) => {
                setCategory(category);
                setSubCategory(subCategory);
              }}
              defaultSubCategory={defaultSubCategory}
            />
          )}
        </Flex>
        <Box mt={["32px", "48px"]}>
          {showProducts.length} {t("common:Products")}
        </Box>
      </Flex>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: ["1fr 1fr", "1fr 1fr 1fr 1fr"],
          gridTemplateRows: ["1fr", "auto"],
          justifyItems: "center",
          gap: "32px"
        }}
        mt={["32px", "16px"]}
      >
        {showProducts.slice(sliceStart, sliceStart + itemsPerPage).map((product, i) => (
          <Product key={i} product={product} />
        ))}
      </Box>
      <Flex justifyContent={["center", "flex-end"]} mt="64px">
        <Pagination
          count={totalPages}
          onChange={(event, page) => {
            setSliceStart((page - 1) * itemsPerPage);
          }}
        />
      </Flex>
    </Flex>
  );
}
