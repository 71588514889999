import React, { useEffect, useState } from "react";
import { Box, Flex } from "reflexbox";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { slideInDown, slideOutUp } from "react-animations";
import { MenuItem } from "./menu-item";
import Socials from "../social-buttons";
import { useTranslation } from "react-i18next";

const MenuStyles = makeStyles({
  "@keyframes slideInDownAnimation": slideInDown,
  "@keyframes slideOutUpAnimation": slideOutUp,
  openMenu: {
    animation: "$slideInDownAnimation 0.1s linear"
  },
  closeMenu: {
    animation: "$slideOutUpAnimation 0.1s linear"
  }
});

export function Menu({ open, onAnimationEndCallback, onClickCallback }) {
  const classes = MenuStyles();

  const { t } = useTranslation("common");

  const pages = [
    { title: t("Products"), link: "/products" },
    { title: t("Inspirations"), link: "/inspirations" },
    { title: t("Manufacturers"), link: "/manufacturers" },
    // { title: t("About"), link: "/about" },
    { title: t("Contact"), link: "/contact" }
  ];

  const [show, setShow] = useState(open);

  useEffect(() => {
    if (open) {
      setShow(true);
    }
  }, [open]);

  const handleOnAnimationEnd = () => {
    if (!open) {
      setShow(false);
      if (onAnimationEndCallback) {
        onAnimationEndCallback();
      }
    }
  };

  return (
    show && (
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          top: 0,
          left: 0,
          right: 0,
          zIndex: 50
        }}
        pt={["96px", "192px"]}
      >
        <div
          style={{ height: "100%", backgroundColor: "white" }}
          className={clsx({ [classes.openMenu]: open, [classes.closeMenu]: !open })}
          onAnimationEnd={handleOnAnimationEnd}
        >
          <Flex flexDirection="column" alignItems="flex-start" height="100%" overflowY="auto" pl={["0", "48px"]}>
            {pages.map((page, index) => (
              <Box pl="16px">
                <MenuItem key={index} page={page} onClickCallback={onClickCallback} />
              </Box>
            ))}
            <Flex justifyContent="center">
              <Flex py="36px" pl={["8px", "0"]}>
                <Socials />
              </Flex>
            </Flex>
          </Flex>
        </div>
      </Box>
    )
  );
}
