import React, { useState, useEffect } from "react";
import { api } from "../api";
import { useLanguage } from "../hooks/useLanguage";

export const CatalogContext = React.createContext();

export const CatalogContextProvider = (props) => {
  const [context, setContext] = useState({
    catalog: [],
    loading: true
  });

  const language = useLanguage();

  useEffect(() => {
    api.get(`/${language}/catalog/all`).then((res) => {
      setContext({ catalog: res.data, loading: false });
    });
  }, [language]);

  return <CatalogContext.Provider value={context}>{props.children}</CatalogContext.Provider>;
};
