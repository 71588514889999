import React from "react";
import { usePagination } from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";

import { Flex } from "reflexbox";
import { ArrowForward, ArrowBack } from "@material-ui/icons";

const useStyles = makeStyles({
  button: {
    cursor: "pointer",
    backgroundColor: "transparent",
    border: "1px solid #707070",
    padding: "0",
    width: "64px",
    outline: "none",
    boxShadow: "none",
    color: "#707070",
    "&:disabled": {
      color: "#707070"
    }
  },
  page: {
    width: "26px",
    height: "26px",
    margin: "0 8px"
  }
});

export default (props) => {
  const classes = useStyles();
  const { items } = usePagination({
    count: props.count,
    onChange: props.onChange
  });

  return (
    <Flex className={classes.ul} style={{ color: "#707070" }}>
      {items.map(({ page, type, selected, ...item }, index) => {
        let children = null;

        if (type === "start-ellipsis" || type === "end-ellipsis") {
          children = (
            <Flex alignItems="center" className={classes.page}>
              <div style={{ borderTop: "1px solid #707070", width: "100%" }} />
            </Flex>
          );
        } else if (type === "page") {
          children = (
            <Flex
              className={classes.page}
              justifyContent="center"
              type="button"
              style={{ borderBottom: selected ? "2px solid #E5A836" : undefined, cursor: "pointer" }}
              {...item}
            >
              {page}
            </Flex>
          );
        } else if (type === "previous") {
          children = (
            <button className={classes.button} {...item}>
              <Flex justifyContent="center">
                <ArrowBack />
              </Flex>
            </button>
          );
        } else {
          children = (
            <button className={classes.button} {...item}>
              <Flex justifyContent="center">
                <ArrowForward />
              </Flex>
            </button>
          );
        }

        return <Flex key={index}>{children}</Flex>;
      })}
    </Flex>
  );
};
