import React, { useEffect, useState } from "react";
import { Box, Flex } from "reflexbox";
import { makeStyles } from "@material-ui/core";
import { H2 } from "../../components/h2";
import { Navbar } from "../../components/navbar";
import { useTheme } from "emotion-theming";
import { ArrowForward } from "@material-ui/icons";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";
import { Manufacturers } from "./manufacturers";
import { Inspirations } from "./inspirations";
import { Container } from "../../components/container";
import { api } from "../../api";
import { useLanguage } from "../../hooks/useLanguage";

const useStyles = makeStyles({
  highlight: {
    fontWeight: "bold",
    color: ({ theme }) => theme.colors.primary
  },
  underline: {
    textDecoration: ({ theme }) => `underline ${theme.colors.primary}`
  },
  link: {
    textDecoration: "none",
    color: "black"
  }
});

export default () => {
  const theme = useTheme();

  const classes = useStyles({ theme });

  const { t } = useTranslation(["common", "home"]);
  const language = useLanguage();

  const [mainDescription, setMainDescription] = useState();

  useEffect(() => {
    api.get(`${language}/textpages/1279958`).then((res) => {
      const textpage = res.data.textpage;
      setMainDescription(textpage.content);
    });
  }, [language]);

  return (
    <>
      <Box
        style={{ position: "absolute", left: 0, right: 0, zIndex: 100, backgroundColor: "rgba(255, 255, 255, 0.4)" }}
      >
        <Box>
          <Navbar color="black" />
        </Box>
      </Box>
      <Box style={{ height: "100vh", zIndex: -1 }}>
        {/* {video ? ( */}
        <video style={{ width: "100%", height: "100%", objectFit: "cover" }} autoPlay playsInline muted loop>
          {/* <source src={video} /> */}
          <source src="/assets/homepage-video.mp4" />
        </video>
      </Box>
      <Box mt="64px">
        <Box overflowX="hidden">
          <ScrollAnimation animateIn="slideInRight">
            <H2 title={t("home:lets-talk-about-us.header")} overflowX="initial" textAlign="left" />
          </ScrollAnimation>
        </Box>
        <Container mt="64px">
          <Flex justifyContent="center">
            <Box>
              <div dangerouslySetInnerHTML={{ __html: mainDescription }} />
              {/* <Box mt="48px">
                <Link to="about" className={classes.link}>
                  <Flex>
                    <ArrowForward /> {t("home:lets-talk-about-us.description.Learn More")}
                  </Flex>
                </Link>
              </Box> */}
            </Box>
          </Flex>
        </Container>
      </Box>
      <Box mt="128px">
        <Manufacturers />
      </Box>
      <Box mt="128px" mb={["48px", "160px"]}>
        <Inspirations />
      </Box>
    </>
  );
};
