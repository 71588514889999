import React from "react";
import { Box, Flex } from "reflexbox";

export function HR(props) {
  const { color, ...rest } = props;

  return (
    <Flex justifyContent="center" mt={["96px", "160px"]} {...rest}>
      <Box width={["75%", "30%"]}>
        <div
          style={{
            borderColor: color,
            borderStyle: "solid",
            borderTopWidth: "2px",
            borderBottomWidth: "0",
            borderLeftWidth: "0",
            borderRightWidth: "0",
            width: "100%"
          }}
        ></div>
      </Box>
    </Flex>
  );
}
