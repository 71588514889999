import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { Box, Flex } from "reflexbox";
import { Navbar } from "../../components/navbar";
import { api } from "../../api";
import { CatalogContext } from "../../contexts/catalog-context";
import { ProductsGrid } from "../../components/products-grid/products-grid";
import ReactLoading from "react-loading";
import { useLanguage } from "../../hooks/useLanguage";
import { HR } from "../../components/hr";
import { useTheme } from "emotion-theming";
import { useTranslation } from "react-i18next";
import { Container } from "../../components/container";

export default () => {
  const { brandId } = useParams();
  const theme = useTheme();

  const language = useLanguage();
  const { t } = useTranslation("common");

  const [brand, setBrand] = useState(null);

  useEffect(() => {
    api.get(`/${language}/brands/${brandId}`).then((res) => {
      setBrand(res.data);
    });
  }, [language, brandId]);

  const { catalog, loading } = useContext(CatalogContext);

  return (
    <>
      <Navbar />
      <Flex justifyContent="flex-end" pr="48px">
        <Link to="/manufacturers" style={{ color: theme.colors.primary }}>
          {t("common:Return")}
        </Link>
      </Flex>
      <Container>
        {brand && (
          <Box>
            <Flex flex="row" justifyContent={["center", "flex-start"]} height="200px">
              <img src={brand.image.src} height="100%" alt={brand.title} title={brand.title} />
            </Flex>
            <Box>
              <div dangerouslySetInnerHTML={{ __html: brand.content }} width="100%"></div>
            </Box>
          </Box>
        )}
        <HR color={theme.colors.primary} mt="64px" />
        {brand && (
          <Box mt="96px" sx={{ fontWeight: "bold", fontSize: ["14pt", "20pt"] }} mb="32px">
            {language === "fr" ? t("common:Products") + " " + brand.title : brand.title + " " + t("common:Products")}
          </Box>
        )}
        {loading ? (
          <ReactLoading type={"bubbles"} color={"#333"} height={667} width={375} />
        ) : (
          <ProductsGrid products={catalog.filter((x) => x.brand.id === parseInt(brandId))} />
        )}
      </Container>
    </>
  );
};
