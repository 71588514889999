import React, { useEffect, useState, useContext } from "react";
import { Link, Route, Switch } from "react-router-dom";
import { HoverItem } from "../../components/hover-item/hover-item";
import { Navbar } from "../../components/navbar";
import { useLanguage } from "../../hooks/useLanguage";
import ScrollAnimation from "react-animate-on-scroll";
import { Container } from "../../components/container";
import { Box } from "reflexbox";
import InspirationsDetail from "../inspirations-detail";
import { ProjectContext } from "../../contexts/project-context";

function Project({ article }) {
  return (
    <Link to={`/inspirations/${article.id}`}>
      <HoverItem image={article.image.src} title={article.title} />
    </Link>
  );
}

export default ({ match }) => {
  const language = useLanguage();

  const { projects, loading } = useContext(ProjectContext);

  const [blogArticles, setBlogArticles] = useState([]);

  useEffect(() => {
    const locale = language === "en" ? "en_US" : "fr_CA";
    const p = projects.filter((x) => x.mainLanguage === locale).map((x) => x[locale]);
    setBlogArticles(p);
  }, [language, projects]);

  return (
    <>
      <Navbar />
      <Box mb={["64px", "96px"]}>
        <Switch>
          <Route path={`${match.path}/:projectId`} component={InspirationsDetail} />
        </Switch>
      </Box>
      <Container
        sx={{ display: "grid", gridTemplateColumns: ["1fr", "1fr 1fr 1fr"], gap: "32px" }}
        mb={["64px", "128px"]}
      >
        {blogArticles.map((article, index) => (
          <ScrollAnimation key={index} animateIn="bounceIn" duration={0.5}>
            <Box height="256px">
              <Project article={article} />
            </Box>
          </ScrollAnimation>
        ))}
      </Container>
    </>
  );
};
