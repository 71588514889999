import { useTheme } from "emotion-theming";
import React, { useContext, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hooks/useLanguage";
import ReactLoading from "react-loading";
import { Flex } from "reflexbox";
import { Container } from "../../components/container";
import { H2 } from "../../components/h2";
import { HR } from "../../components/hr/hr";
import { Navbar } from "../../components/navbar";
import { ProductsGrid } from "../../components/products-grid/products-grid";
import { CatalogContext } from "../../contexts/catalog-context";
import { useEffect } from "react";
import { api } from "../../api";
import { FormControlLabel } from "@material-ui/core";

export default () => {
  const theme = useTheme();

  const language = useLanguage();

  const { t } = useTranslation(["common", "products"]);
  const { catalog, loading } = useContext(CatalogContext);
  const [acousticCatories, setAcousticCatories] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  function handleCheckboxChange(key) {
    return (event) => {
      const categoryCopy = { ...acousticCatories };
      categoryCopy[key].checked = event.target.checked;
      setAcousticCatories(categoryCopy);
    };
  }

  function filteringFunction(product, categoryIds) {
    for (let productCategoryId in product.categories) {
      for (let productId of categoryIds) {
        if (productId == productCategoryId) {
          return true;
        }
      }
    }
    return false;
  }

  function RenderCheckBoxs(props) {
    const { categories } = props;
    const content = [];
    for (const categoryName in categories) {
      content.push(
        <FormControlLabel
          key={categoryName}
          control={
            <Checkbox
              color="primary"
              checked={categories[categoryName].checked}
              onChange={handleCheckboxChange(categoryName)}
            />
          }
          label={categoryName}
        />
      );
    }
    return content;
  }

  useEffect(() => {
    api.get(`/${language}/categories`).then((res) => {
      const categories = res.data.filter((x) => x.parent?.resource?.id === 4327915);
      let categoriesObject = {};
      for (const category of categories) {
        categoriesObject[category.title] = {
          obj: category,
          checked: false
        };
      }
      setAcousticCatories(categoriesObject);
    });
  }, [language]);

  useEffect(() => {
    if (catalog.lentgh == 0) {
      return;
    }

    const categoryIds = [];
    for (let category in acousticCatories) {
      if (acousticCatories[category].checked) {
        categoryIds.push(acousticCatories[category].obj.id);
      }
    }

    if (categoryIds.length == 0) {
      setFilteredProducts(catalog.filter((x) => 4327915 in x.categories));
    } else {
      setFilteredProducts(catalog.filter((x) => filteringFunction(x, categoryIds)));
    }
  }, [acousticCatories, catalog]);

  return (
    <>
      <Navbar />
      <H2 title={t("Products")} ml={["", "156px"]} />
      {loading ? (
        <Flex justifyContent="center">
          <ReactLoading type={"bubbles"} color={"#333"} height={667} width={375} />
        </Flex>
      ) : (
        <Container mt="32px" mb="128px">
          <Container my="32px" mx="0">
            <RenderCheckBoxs categories={acousticCatories} />
          </Container>
          <ProductsGrid products={filteredProducts} />
        </Container>
      )}
      <HR display={["block", "none"]} color={theme.colors.secondary} mb="64px" />
    </>
  );
};
