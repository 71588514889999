import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    position: "relative",
    width: "100%",
    height: "100%"
  },
  image: {
    display: "block",
    width: "100%",
    height: "100%"
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
    width: "100%",
    opacity: 0,
    transition: "0.5s ease",
    backgroundColor: "#33333380",
    "&:hover": {
      opacity: 1,
      cursor: "pointer"
    }
  },
  text: {
    color: "white",
    fontSize: "20px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center"
  }
});

export function HoverItem(props) {
  const classes = useStyles();
  const image = props.image;
  const title = props.title;

  return (
    <div className={classes.container}>
      <img src={image} className={classes.image} alt={title} title={title} />
      <div className={classes.overlay}>
        <div className={classes.text}>
          <div>{title}</div>
          <div style={{ fontSize: "32pt", lineHeight: "24pt" }}>+</div>
        </div>
      </div>
    </div>
  );
}
