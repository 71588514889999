import React from "react";
import { Box } from "reflexbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faInstagram,
  faFacebook,
  faTwitter,
  faPinterest,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";

export default function (props) {
  const socials = [
    { icon: faYoutube, link: "https://www.youtube.com/channel/UCjwJJl35uAeMSY3Zt68T50Q" },
    { icon: faInstagram, link: "https://www.instagram.com/lgacoustic/" },
    { icon: faFacebook, link: "https://www.facebook.com/profile.php?id=61551844491012" },
    { icon: faLinkedin, link: "https://www.linkedin.com/company/lg-acoustic" }
  ];

  const color = props.color || "black";

  return (
    <>
      {socials.map((social, i) => (
        <Box key={i} sx={{ fontSize: ["18pt", "24pt"], padding: ["0 8px", "0 16px"] }}>
          <a href={social.link} style={{ color }} target="_blank">
            <FontAwesomeIcon icon={social.icon} />
          </a>
        </Box>
      ))}
    </>
  );
}
