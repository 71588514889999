import React from "react";
import { Link } from "react-router-dom";
import { Flex } from "reflexbox";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  title: {
    fontWeight: "bold",
    color: "white"
  },
  link: {
    textDecorationLine: "none",
    color: "white",
    "&:hover": {
      textDecorationLine: "underline !important"
    }
  }
});

export function SiteMap() {
  const classes = useStyles();

  const { t } = useTranslation(["common", "footer"]);

  const sitemap = [
    {
      title: t("footer:Categories"),
      children: [
        {
          name: t("common:Products"),
          to: "/products"
        },
        {
          name: t("common:Manufacturers"),
          to: "/manufacturers"
        },
        {
          name: t("common:Inspirations"),
          to: "/inspirations"
        }
      ]
    },
    // {
    //   title: t("common:Products"),
    //   children: [
    //     {
    //       name: t("common:Quick ship"),
    //       to: "/products/quick-ship"
    //     },
    //     {
    //       name: t("common:Lighting"),
    //       to: "/products/lighting"
    //     },
    //     {
    //       name: t("common:Furniture"),
    //       to: "/products/furniture"
    //     }
    //   ]
    // },
    // {
    //   title: t("footer:Company"),
    //   children: [
    //     {
    //       name: t("common:About"),
    //       to: "/about"
    //     },
    //     {
    //       name: t("common:Contact"),
    //       to: "/contact"
    //     }
    //   ]
    // },
    {
      title: t("footer:Help"),
      children: [
        {
          name: t("footer:Shipping and Returns"),
          to: "/shipping-and-returns"
        },
        {
          name: t("footer:Payment Methods"),
          to: "/payment-methods"
        }
      ]
    }
  ];

  return (
    sitemap &&
    sitemap.map((item, i) => (
      <Flex key={i} flexDirection="column">
        <div className={classes.title} style={{ paddingBottom: "12px" }}>
          {item.title}
        </div>
        {item.children.map((child, j) => (
          <Link key={j} to={child.to} className={classes.link} style={{ padding: "6px 0" }}>
            {child.name}
          </Link>
        ))}
      </Flex>
    ))
  );
}
