import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    width: "100%",
    paddingTop: "56.25%" /* 16:9 Aspect Ratio */,
    position: "relative" /* If you want text inside of it */,
    overflow: "hidden"
  },

  /* If you want text inside of the container */
  content: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },

  img: {
    position: "relative",
    top: "50%",
    transform: "translateY(-50%)",
    objectFit: "stretch"
  }
});

export function PerfectImageRatio({ src, alt, title }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <img src={src} width="100%" className={classes.img} alt={alt} title={title} />
      </div>
    </div>
  );
}
