import React from "react";
import { Link } from "react-router-dom";
import { HoverItem } from "../hover-item/hover-item";

export function Product(props) {
  const product = props.product;

  return (
    <Link to={`/products/${product.id}`}>
      <HoverItem image={product.image.src} title={product.title} />
    </Link>
  );
}
