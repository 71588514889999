import React, { useEffect, useState } from "react";
import { Box, Flex } from "reflexbox";

function ContainedImage(props) {
  const { src, alt, ...rest } = props;
  return (
    <Box {...rest}>
      <img src={src} alt={alt} width="100%" />
    </Box>
  );
}

export function ImagePreview(props) {
  const { images } = props;
  const [imagePreview, setImagePreview] = useState(images[0]);

  useEffect(() => {
    setImagePreview(images[0]);
  }, [images]);

  return (
    <Flex flexDirection={["column", "row"]}>
      <ContainedImage flex="2" src={imagePreview.src} alt={imagePreview.title} />
      <Box
        flex="5"
        mt={["8px", "0"]}
        ml={["0", "8px"]}
        sx={{ display: "grid", gap: "8px", gridTemplate: "1fr 1fr / repeat(5, 1fr)", gridAutoFlow: ["row", "column"] }}
      >
        {images.length > 1 &&
          images.map((image, index) => (
            <ContainedImage
              key={index}
              src={image.src}
              alt={image.title}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setImagePreview(image);
              }}
            />
          ))}
      </Box>
    </Flex>
  );
}

export function ImagePreviewV2(props) {
  const { images } = props;
  const [imagePreview, setImagePreview] = useState(images[0]);

  useEffect(() => {
    setImagePreview(images[0]);
  }, [images]);

  return (
    <Box mx={["", "256px"]}>
      <Flex justifyContent="flex-start">
        <ContainedImage src={imagePreview.src} alt={imagePreview.title} width="512px" />
      </Flex>
      {images.length > 1 && (
        <Flex flexWrap="wrap" justifyContent="center">
          {images.map((image, index) => (
            <ContainedImage
              flex={["20%", "1"]}
              key={index}
              src={image.src}
              alt={image.title}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setImagePreview(image);
              }}
            />
          ))}
        </Flex>
      )}
    </Box>
  );
}

export function ImagePreviewOld(props) {
  const { images } = props;
  const [imagePreview, setImagePreview] = useState(images[0]);

  useEffect(() => {
    setImagePreview(images[0]);
  }, [images]);

  return (
    <Box
      sx={{
        display: "grid",
        gap: ["2px", "16px"],
        gridTemplate: ["4fr 4px 1fr 1fr / repeat(12, 1fr)", "5fr 7fr / 18fr 0.75fr repeat(24, 1fr)"]
      }}
    >
      <ContainedImage
        src={imagePreview.src}
        alt={imagePreview.title}
        sx={{ gridRowEnd: ["span 1", "span 2"], gridColumnEnd: ["span 12", "span 1"] }}
      />
      <Box sx={{ gridRowEnd: ["span 1", "span 2"], gridColumnEnd: ["span 12", "span 1"] }} />
      {images.slice(0, 2).map((image, index) => (
        <ContainedImage
          key={index}
          src={image.src}
          alt={image.title}
          sx={{
            cursor: "pointer",
            gridColumnEnd: ["span 3", "span 6"]
          }}
          onClick={() => {
            setImagePreview(image);
          }}
        />
      ))}
      {images.slice(4, 7).map((image, index) => (
        <ContainedImage
          key={index}
          src={image.src}
          flex="1"
          sx={{
            cursor: "pointer",
            gridColumnEnd: ["span 4", "span 8"]
          }}
          onClick={() => {
            setImagePreview(image);
          }}
        />
      ))}
    </Box>
  );
}
