import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Box, Flex } from "reflexbox";
import { useLanguage } from "../../hooks/useLanguage";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { PerfectImageRatio } from "../../components/perfect-image-ratio/perfect-image-ratio";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { Container } from "../../components/container";
import { ProjectContext } from "../../contexts/project-context";

const useStyles = makeStyles({
  container: {
    position: "relative"
  },
  image: {
    display: "block",
    width: "100%",
    height: "auto"
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
    width: "100%",
    transition: "0.5s ease",
    backgroundColor: "#33333390"
  },
  text: {
    color: "transparent",
    fontSize: "5vw",
    fontWeight: "bold",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center"
  },
  arrow: {
    border: "1px solid white",
    cursor: "pointer",
    color: "white"
  }
});

export default () => {
  const classes = useStyles();
  const { projectId } = useParams();

  const language = useLanguage();

  const { projects, loading } = useContext(ProjectContext);

  const [project, setProject] = useState(null);

  useEffect(() => {
    if (projects.length > 0) {
      const locale = language === "en" ? "en_US" : "fr_CA";
      const filteredProject = projects.filter((x) => x.id == projectId).map((x) => x[locale])[0];
      if (filteredProject) {
        const p = { ...filteredProject };
        const re = new RegExp("&lt;Carousel&gt;.*?&lt;\\/Carousel&gt;", "gs");
        const imagesHtmlMatches = p.content.match(re);
        p.content = p.content.replace(re, "");
        if (imagesHtmlMatches && imagesHtmlMatches.length > 0) {
          const srcs = [...imagesHtmlMatches[0].matchAll(new RegExp('src="(.*?)"', "g"))].map((match) => match[1]);
          p.carouselSrcs = srcs;
        } else {
          p.carouselSrcs = [p.image.src];
        }
        setProject(p);
      }
    }
  }, [language, projectId, projects]);

  return (
    <>
      {project && (
        <>
          <Box className={classes.container}>
            {project.carouselSrcs?.length > 0 && (
              <Carousel
                infiniteLoop={true}
                showStatus={false}
                renderThumbs={() => null}
                renderArrowPrev={(clickHandler, hasPrev, label) =>
                  hasPrev && (
                    <Flex
                      flexDirection="column"
                      justifyContent="center"
                      sx={{ position: "absolute", left: ["8px", "24px"], top: 0, bottom: 0, zIndex: 2 }}
                    >
                      <Flex onClick={clickHandler} alignItems="center" className={classes.arrow} p={["6px", "12px"]}>
                        <ArrowBack />
                      </Flex>
                    </Flex>
                  )
                }
                renderArrowNext={(clickHandler, hasNext, label) =>
                  hasNext && (
                    <Flex
                      flexDirection="column"
                      justifyContent="center"
                      sx={{ position: "absolute", right: ["8px", "24px"], top: 0, bottom: 0, zIndex: 2 }}
                    >
                      <Flex onClick={clickHandler} alignItems="center" className={classes.arrow} p={["6px", "12px"]}>
                        <ArrowForward />
                      </Flex>
                    </Flex>
                  )
                }
              >
                <Box maxHeight="100vh">
                  <PerfectImageRatio src={project.carouselSrcs[0]} />
                  <div className={classes.overlay}>
                    <Box
                      className={classes.text}
                      sx={{
                        WebkitTextStroke: ["1px white", "3px white"]
                      }}
                    >
                      {project.title}
                    </Box>
                  </div>
                </Box>
                {project.carouselSrcs.map((src, index) => (
                  <Box key={index} maxHeight="100vh" style={{ backgroundColor: "#333" }}>
                    <WidthOrHeightImage src={src} />
                  </Box>
                ))}
              </Carousel>
            )}
          </Box>
          <Container mt={["24px", "64px"]}>
            <div dangerouslySetInnerHTML={{ __html: project.content }} />
          </Container>
        </>
      )}
    </>
  );
};

const widthOrHeightStyles = makeStyles({
  image: {
    width: "100%",
    maxHeight: "100vh",
    objectFit: "contain"
  }
});

function WidthOrHeightImage({ src, alt, title }) {
  const classes = widthOrHeightStyles();

  return <img src={src} alt={alt} title={title} className={classes.image} />;
}
