import React from "react";
import { Box } from "reflexbox";

export function Container({ children, ...rest }) {
  return (
    <Box mx={["16px", "128px"]} {...rest}>
      {children}
    </Box>
  );
}
