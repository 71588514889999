import React from "react";
import { Flex, Box } from "reflexbox";
import { Link } from "react-router-dom";
import { Navbar } from "../../components/navbar";
import { useSortedBrands } from "../../hooks/useSortedBrands";
import { Container } from "../../components/container";

export default ({ match }) => {
  const sortedBrands = useSortedBrands();

  return (
    <>
      <Navbar />
      <Container
        sx={{
          display: "grid",
          gridTemplateColumns: ["1fr 1fr", "1fr 1fr 1fr 1fr"],
          justifyItems: "center",
          gap: "32px"
        }}
        mt={["32px", "64px"]}
      >
        {sortedBrands.map((brand, index) => (
          <Box
            key={index}
            width={["128px", "256px"]}
            height={["128px", "256px"]}
            sx={{
              border: "2px solid transparent",
              ":hover": {
                transform: "scale(1.2)"
              }
            }}
          >
            <Link to={`${match.url}/${brand.id}`}>
              <img src={brand.image.src} width="100%" height="100%" alt={brand.title} title={brand.title} />
            </Link>
          </Box>
        ))}
      </Container>
      <Flex justifyContent="center">
        <Box width={["50%", "30%"]} mt="32px">
          <hr />
        </Box>
      </Flex>
    </>
  );
};
