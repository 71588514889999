import React, { useState, useEffect } from "react";
import { Product } from "../../components/product";
import { api } from "../../api";
import { useLanguage } from "../../hooks/useLanguage";

export function UnloadedProduct(props) {
  const { unloadedProduct } = props;

  const [product, setProduct] = useState(null);

  const language = useLanguage();

  useEffect(() => {
    api.get(`/${language}/${unloadedProduct.resource.url}`).then((res) => {
      setProduct(res.data.product);
    });
  }, [language, unloadedProduct]);

  return product && <Product product={product} />;
}
