import React, { useEffect, useState } from "react";
import { Navbar } from "../../components/navbar";
import { useParams } from "react-router-dom";
import { api } from "../../api";
import { Flex, Box } from "reflexbox";
import { ImagePreview } from "./image-preview";
import { useTheme } from "emotion-theming";
import { makeStyles } from "@material-ui/core/styles";
import { UnloadedProduct } from "../../components/unloaded-product/unloaded-product";
import { useLanguage } from "../../hooks/useLanguage";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Container } from "../../components/container";

const useStyles = makeStyles({
  hr: {
    borderColor: ({ theme }) => theme.colors.primary,
    borderTopStyle: "solid",
    borderTopWidth: "2px",
    width: "100%"
  }
});

function BrandTitleByBrand(props) {
  const theme = useTheme();

  const { product, brand } = props;

  const { t } = useTranslation("common");

  return (
    <>
      {product && (
        <Box style={{ fontWeight: "bold", fontSize: "24pt" }} mt="32px">
          {product.title}
        </Box>
      )}
      {brand && (
        <Box color="grey">
          <span>
            {t("By")}{" "}
            <Link to={`/manufacturers/${brand.id}`} style={{ textDecoration: "none", color: theme.colors.primary }}>
              {brand.title}
            </Link>
          </span>
        </Box>
      )}
    </>
  );
}

function SimilarProducts(props) {
  const { productRelations } = props;

  const { t } = useTranslation("common");

  return (
    <Box>
      <Flex justifyContent="center">
        <Box
          sx={{
            fontSize: ["24pt", "42pt"],
            color: "transparent",
            WebkitTextStroke: "1px black"
          }}
        >
          {t("Similar products")}
        </Box>
      </Flex>
      {/* <Box
        sx={{
          display: "grid",
          gap: "32px",
          gridTemplateColumns: ["1fr 1fr", `repeat(5, 1fr)`]
        }}
        mt={["96px", "128px"]}
      > */}
      <Flex
        flexWrap="wrap"
        mt={["96px", "128px"]}
        justifyContent={["center", productRelations?.length <= 5 ? "center" : "flex-start"]}
      >
        {productRelations.map((relation, index) => (
          <Box key={index} flex={["0 1 50%", "0 1 20%"]} p="16px">
            <UnloadedProduct unloadedProduct={relation.relatedProduct} />
          </Box>
        ))}
      </Flex>
      {/* </Box> */}
    </Box>
  );
}

export default () => {
  const theme = useTheme();

  const classes = useStyles({ theme });

  const { productId } = useParams();

  const language = useLanguage();

  const [product, setProduct] = useState();
  const [productImages, setProductImages] = useState([]);
  const [brand, setBrand] = useState();
  const [productRelations, setProductRelations] = useState([]);

  useEffect(() => {
    api.get(`/${language}/products/${productId}`).then((res) => {
      const p = res.data.product;
      api.get(`/${language}/brands/${p.brand.resource.id}`).then((r) => {
        setBrand(r.data);
      });
      setProduct(p);
    });

    api.get(`/${language}/products/${productId}/images`).then((res) => {
      setProductImages(res.data.productImages);
    });

    api.get(`/${language}/products/${productId}/relations`).then((res) => {
      setProductRelations(res.data.productRelations);
    });
  }, [language, productId]);

  return (
    <>
      <Navbar />
      {productImages?.length > 0 && <ImagePreview images={productImages} />}
      <Container>
        <Flex flexDirection="column" sx={{ textAlign: ["center", "initial"] }} mb={["64px", "128px"]}>
          <BrandTitleByBrand product={product} brand={brand} />
          {product && (
            <Box mt="64px" overflowX="hidden">
              <div dangerouslySetInnerHTML={{ __html: product.content }} />
            </Box>
          )}

          {productRelations?.length > 0 ? (
            <>
              <Flex justifyContent="center" mt={["64px", "128px"]}>
                <Box width={["75%", "60%"]}>
                  <div className={classes.hr} />
                </Box>
              </Flex>
              <Box mt={["96px", "128px"]}>
                <SimilarProducts productRelations={productRelations} />
              </Box>
            </>
          ) : null}

          <Flex justifyContent="center">
            <Box width={["75%", "30%"]} mt={["96px", "160px"]}>
              <hr />
            </Box>
          </Flex>
        </Flex>
      </Container>
    </>
  );
};
